<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <a
            @click="$router.back()"
            href="#"
            class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg d-flex flex-row align-items-center">
          <i class="mdi mdi-chevron-left"></i>
          Kembali
        </a>
        <div class="card card-custom">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                Daftar Mata Pelajaran
              </span>
            </h3>
            <!--            <div class="card-toolbar">-->
            <!--              <router-link :to="`/mapel/${idKurikulum}/ujian/${idUjian}/soal/create`" href="#" class="btn btn-success font-weight-bolder font-size-sm">-->
            <!--                Tambah Soal Baru</router-link>-->
            <!--            </div>-->
          </div>
          <div class="card-body pt-4">
            <!--          <b-card-text>Header and footers using slots.</b-card-text>-->
            <b-row class="justify-content-between">
              <b-col cols="auto">
                <b-form-group

                >
                  <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="auto">
                <b-form-group
                    label="Filter"
                    label-for="filter-input"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="Type to Search"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-table
                :filter="filter"
                :current-page="currentPage"
                :per-page="perPage"
                @filtered="onFiltered"
                :items="currentMapelList"
                :fields="isSiswa?fieldSiswa:fields"
                :busy="isLoading"
                show-empty
                class="table table-head-custom table-vertical-center table-head-bg table-borderless card-table"
                responsive
            >
              <template #empty="scope">
                <h6 class="text-center">Tidak ada mata pelajaran ditemukan</h6>
              </template>
              <template #emptyfiltered="scope">
                <h6 class="text-center">Tidak ada mata pelajaran ditemukan</h6>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #row-details="row" v-if="!isSiswa">
                <div class="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                  Daftar siswa:
                </div>
                <ul
                    class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
                    role="tablist"
                    ref="builder-tab"
                >
                  <li class="nav-item" v-for="(jadwal, i) in row.item.jadwal" :key="`jadwal${jadwal.id}${i}`">
                    <a
                        :class="`nav-link ${i==0?'active':''}`"
                        v-on:click="(event) => setActiveTab(event, row.item)"
                        :data-tab="i"
                        data-toggle="tab"
                        href="#"
                        role="tab"
                    >
                      {{ jadwal.kelas.nama_kelas }}
                    </a>
                  </li>
                </ul>
                <b-tabs class="hide-tabs mt-4" v-model="row.item.tabIndex">
                  <b-tab :active="i==0?'active':null" v-for="(jadwal, i) in row.item.jadwal" :key="jadwal.id">
                    <ul style="list-style: none; columns: 2; -webkit-columns: 2; -moz-columns: 2;">
                      <li v-for="(siswa, i) in jadwal.kelas.det_kelas" :key="siswa.nis">
                        <b-row class="align-items-center">
                          <b-col cols="auto" class="w-50px text-center">
                            {{ i+1 }}
                          </b-col>
                          <b-col cols="auto">
                            <div class="symbol symbol-50 symbol-light mr-2 mt-2">
                              <img :src="`https://eschool.smkn4bdg.sch.id/img.php?src=http://img.smkn4bdg.sch.id/siswa/${siswa.nis}.jpg`" class="symbol-label" alt="" />
                            </div>
                          </b-col>
                          <b-col>
                            <a class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">{{ siswa.siswa.siswa.nama_lengkap }}</a>
                            <span class="text-muted font-weight-bold d-block">{{ siswa.nis }}</span>
                          </b-col>
                        </b-row>
                      </li>
                    </ul>
                  </b-tab>
                </b-tabs>
              </template>
              <template #cell(index)="row">
                {{ perPage * (currentPage - 1) + (row.index + 1) }}
              </template>
              <template #cell(nama_mapel)="data">
                <a
                    href="#"
                    class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                  {{ `${data.item.kurikulum.mapel.nama_mapel} ${isSiswa?'':'- Tingkat '+parseInt((data.item.kurikulum.semester/2)+1)}` }}
                </a>
                <span class="text-muted font-weight-bold d-block">
                  {{ data.item.kurikulum.mapel.kategori_mapel.nama_kategori }}
                </span>

              </template>
              <template #cell(guru)="data">
                <div class="d-flex align-items-center mb-2" v-for="(guru, i) in data.item.guru" :key="guru.grnrs">
                  <!--begin::Pic-->
                  <a href="#" class="symbol symbol-45 symbol-light mr-3">
                    <img
                        class="symbol-label"
                        :src="`https://eschool.smkn4bdg.sch.id/img.php?src=http://img.smkn4bdg.sch.id/guru/${guru.grnrs}.jpg`"
                        alt=""
                    />
                  </a>
                  <div class="mr-4">
                    <div class="font-size-sm text-muted font-weight-bold">
                      Guru {{ i+1 }}
                    </div>
                    <div class="font-size-sm font-weight-bolder">
                      {{ guru.nama_lengkap }}
                    </div>
                  </div>
                  <!--end::Pic-->
                </div>
              </template>
              <template #cell(kelas)="data">
                <!--                <b-badge class="mr-1" pill href="#" variant="info-light" v-for="jadwal in removeDuplicate(data.item.jadwal)" :key="jadwal">-->
                <!--                  {{ jadwal }}-->
                <!--                </b-badge>-->
                <span
                    class="label label-lg label-inline font-weight-bold py-4 label-light-info mr-1" v-for="jadwal in removeDuplicate(data.item.jadwal)" :key="jadwal">
                  {{ jadwal }}
                </span>
              </template>
              <template #cell(action)="data">
                <span
                    v-if="!isSiswa"
                    @click="data.toggleDetails"
                    class="custom-v-dropdown btn btn-light-info btn-icon mr-1">
<!--                  {{ data.detailsShowing ?'Sembunyikan':'Lihat Deskripsi' }}-->
                  <i :class="`ki ki-bold-arrow-${data.detailsShowing ?'up':'down mt-1'} icon-sm`"></i>
                </span>
                <router-link
                    :to="`/mapel/${data.item.id_kurikulum}/ujian`"
                    class="btn btn-light-primary font-weight-bolder font-size-sm"
                >Daftar Ujian</router-link>
                <!--                  <router-link-->
                <!--                    -->
                <!--                    class="btn btn-icon btn-primary btn-hover-primary shadow-sm"-->
                <!--                    v-b-tooltip.hover.right="'Lihat Daftar ujian'"-->
                <!--                  >-->
                <!--                    <i class="ki ki-bold-arrow-next icon-sm"></i>-->
                <!--                  </router-link>-->
              </template>
            </b-table>
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="right"
                class="my-0"
            ></b-pagination>
          </div>
          <!--          <b-button href="#" variant="primary">Go somewhere</b-button>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import {GET_MAPEL} from "@/core/services/store/mapel.module";
export default {
  name: "DaftarGuru",
  data(){
    return {
      isLoading: false,
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      filter: null,
      fieldSiswa: [
        {
          key: 'index',
          label: 'No',
          sortable: true,
          sortDirection: 'desc',
        },
        {
          key: 'nama_mapel',
          label: "Nama Mata Pelajaran",
          sortable: true
        },
        {
          key: 'guru',
          label: "Guru",
          sortable: true
        },
        {
          key: 'action',
          label: 'Aksi',
          sortable: false,
        }
      ],
      fields: [
        {
          key: 'index',
          label: 'No',
          sortable: true,
          sortDirection: 'desc',
        },
        {
          key: 'nama_mapel',
          label: "Nama Mata Pelajaran",
          sortable: true
        },
        {
          key: 'kelas',
          label: "Kelas",
          sortable: true
        },
        {
          key: 'action',
          label: 'Aksi',
          sortable: false,
        }
      ],
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Daftar Guru" }]);
    this.getData()
  },
  computed: {
    ...mapState({
      errors: state => state.mapel.errors
    }),
    ...mapGetters(["currentMapelList"]),
    currentUser(){
      return this.$currentUser()
    },
    isSiswa(){
      return this.currentUser.user.level==1
    }
  },
  methods: {
    removeDuplicate(kelasArr){
      const arr = kelasArr.map((item)=>{
        return item.kelas.nama_kelas
      })
      return arr.filter((item, pos, self) => {
        return self.indexOf(item) == pos
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onFilteredSiswa(filteredItems, item) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      item.totalRows = filteredItems.length
      item.currentPage = 1
    },
    getData(){
      this.isLoading = true
      this.$store
          .dispatch(GET_MAPEL)
          .then(() => {
            this.isLoading = false
            this.totalRows = this.currentMapelList.length
          })
          .catch(() => {
            this.isLoading = false
            this.$bvToast.toast(this.errors, {
              title: `Gagal Memuat Mata Pelajaran`,
              variant: "danger",
              solid: true
            })
          });
    },
    setActiveTab(event, item) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      item.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },
  }
}
</script>

<style scoped>

</style>
